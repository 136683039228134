.joblist {
  padding-top: 40px;
  padding-left: 20px;
  font-family: "Poppins", sans-serif;
  height: 100vh;
  overflow-y: scroll;

  .heading-button {
    display: flex;
    flex-direction: column;

    margin-right: 80px;
    .head {
      font-size: 30px;
      color: #7f00bb;
      font-weight: 600;
      margin-bottom: 30px;
    }
    .toggle-button {
      button {
        border: none;
        outline: none;
        color: #fff;
        // background: linear-gradient(to right, #CC7A64, #B756A9);
        padding: 10px 20px;
        // box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
        border-radius: 5px;
        margin-right: -10px;
      }
    }
  }
  .job-button {
    margin-left: 630px;

    a {
      text-decoration: none;

      button {
        position: relative;
        z-index: 1;
        margin-top: 0px;
        margin-bottom: -76px;
        text-decoration: none;
        height: 45px;
      }
    }
  }
  .search {
    border: 1px solid #7f00bb;

    border-radius: 10px;
    width: 30vw;
    height: 45px;
    padding: 4px 15px;
  }
  .formControal {
    width: 150px;

    min-width: 150px;

    .select {
      height: 45px;
      color: #7f00bb;
      background: rgba(127, 0, 187, 0.08);
      font-weight: 500;
      font-size: 16px;
    }
  }

  .group {
    .formControal {
      width: 150px;

      min-width: 140px;

      .select {
        height: 45px;
        color: #7f00bb;
        background: rgba(127, 0, 187, 0.08);
        font-weight: 500;
        font-size: 16px;
      }
    }
    .MuiPaper-root {
      .MuiList-root {
        .MuiButtonBase-root {
          padding: 2px 0px 3px 5px;
          .MuiListItemIcon-root {
            all: unset;
            width: 40px;
            .MuiCheckbox-root {
              color: #7f00bb;

              .MuiSvgIcon-root {
                width: 15px;
              }
            }
          }
          .MuiListItemText-root {
            font-size: 12px;
            font-weight: 600;
            font-family: Popine;
          }
        }
      }
    }
  }
  .apply-btn {
    padding: 10px 30px;
    font-size: 19px;
    height: 42px;
  }
  .MuiButton-root {
    // margin-bottom: -100px;
    // margin-right: -91vw;
    margin-right: 10px;

    border: none;
    outline: none;
    background: rgba(127, 0, 187, 0.08);

    border-radius: 20px;
    padding: 10px 30px;
    font-size: 14px;
    color: #7f00bb;
    font-weight: 500;
    position: relative;
    // margin-top: 100px;
  }
  .MuiButton-root:hover {
    color: #7f00bb;
    background: rgba(127, 0, 187, 0.08);
  }
  .hr {
    color: #000000;
  }
  .body {
    margin-top: 17vh !important;

    .MuiBox-root {
      div {
        div {
          .MuiDataGrid-toolbarContainer {
            margin-top: -100px;
            padding-left: 0px;

            button {
              border: none;
              outline: none;
              background: rgba(127, 0, 187, 0.08);
              margin-right: 16px;
              border-radius: 20px;
              padding: 10px 30px;
              font-size: 14px;
              color: #7f00bb;
              font-weight: 500;
            }
          }
        }
      }
      .MuiDataGrid-main {
        .MuiDataGrid-columnHeaders {
          .MuiDataGrid-columnHeadersInner {
            color: #7f00bb;
          }
        }
      }
    }
    .MuiDataGrid-root {
      div {
        div {
          .MuiDataGrid-toolbarContainer {
            margin-top: -100px;
            border-bottom: 2px solid #7f00bb;
            padding-bottom: 10px;
          }
        }
      }
    }
  }
  .default-text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: inherit;
    font-size: 20px;
    font-weight: 600;
    min-height: 60vh;
    color: $theme-purple;

    img {
      display: block;
      margin: auto;
    }
  }
}

.MuiDataGrid-panel {
  .MuiPaper-root {
    width: auto;

    padding-right: 15px;
    position: relative;
    padding-top: 10px;
    border-radius: 10px;
    margin-top: -100px;

    .MuiDataGrid-panelHeader {
      padding: 10px 2px;

      display: none;
      .MuiFormControl-root {
        .css-179tkpm-MuiFormLabel-root-MuiInputLabel-root {
          all: unset;
        }
        .MuiInputLabel-root {
          color: #000000;
          all: unset;
          font-size: 18px;
          font-weight: 500;
        }
        .MuiInput-input {
          font-size: 20px;
          font-weight: 500;
          color: #000000;
        }
      }
    }

    .MuiDataGrid-panelContent {
      display: flex;
      .MuiDataGrid-filterForm {
        .MuiFormControl-root {
          width: auto;
          .css-whhjxc-MuiInputBase-root-MuiInput-root {
            outline: none;
            border: 1.2px solid #7f00bb;
            color: #7f00bb;

            border-radius: 4px;
            padding: 5px 7px;
            font-weight: 500;
          }
        }
      }

      .MuiDataGrid-columnsPanel {
        overflow-y: hidden;
        overflow-y: scroll;
        overflow-x: hidden;

        .MuiDataGrid-columnsPanelRow {
          width: 200px;
          padding-left: 10px;
          .MuiFormControlLabel-root {
            all: unset;
            display: flex;
            align-items: center;
            .MuiSwitch-root {
              width: 20px;
              overflow: hidden;
              margin-bottom: -15px;
              .MuiSwitch-switchBase {
                font-size: 12px;
                background-color: #7f00bb;
                all: unset;
                input[type="checkbox"] {
                  visibility: hidden;
                }

                .MuiSwitch-thumb {
                  all: unset;
                  position: absolute;
                  top: 0;
                  left: 0;
                  height: 15px;
                  width: 15px;
                  background-color: #999999;
                  border-radius: 3px;
                }
                .MuiTouchRipple-root {
                  left: 3px;
                  bottom: 3px;
                  width: 6px;
                  height: 10px;
                  border: solid white;
                  border-width: 0 3px 3px 0;
                  -webkit-transform: rotate(45deg);
                  -ms-transform: rotate(45deg);
                  transform: rotate(45deg);
                }

                input:checked ~ .MuiSwitch-thumb {
                  background-color: #7f00bb;
                }
                .MuiSwitch-thumb:after {
                  content: "";
                  position: absolute;
                  display: none;
                }
                input:checked ~ .MuiSwitch-thumb:after {
                  display: block;
                }
                .MuiSwitch-thumb:after {
                  left: 3px;
                  bottom: 3px;
                  width: 6px;
                  height: 10px;
                  border: solid white;
                  border-width: 0 3px 3px 0;
                  -webkit-transform: rotate(45deg);
                  -ms-transform: rotate(45deg);
                  transform: rotate(45deg);
                }
              }
              .MuiSwitch-track {
                background-color: #000000;
                height: 8px;
                width: 15px;
              }
            }
            .MuiTypography-root {
              padding: 2px 10px;

              font-weight: 500;
              font-size: 14px;
              width: 200px;
            }
          }
        }
      }
    }
    .MuiDataGrid-panelContent {
      .MuiFormControl-root {
        padding: 2px 15px;

        .MuiInputLabel-root {
          all: unset;
          color: #7f00bb;
          font-weight: 600;
          font-size: 16px;
        }
        .MuiInput-root {
          width: 130px;
          margin-right: 10px;

          .css-1q5w4r7-MuiInputBase-root-MuiInput-root-MuiSelect-root:before {
            border: none;
            all: unset;
          }
          .MuiNativeSelect-select {
            width: 200px;
            outline: none;
            border: 1.2px solid #7f00bb;
            color: #7f00bb;

            border-radius: 4px;
            padding: 10px 7px;
            font-weight: 500;
            option {
              color: black;
              font-size: 14px;
              font-weight: 400;
              margin: 10px;
              padding: 10px;
            }
          }
          .css-1q5w4r7-MuiInputBase-root-MuiInput-root-MuiSelect-root:after {
            border-bottom: none !important;
          }
        }
      }
    }

    .MuiDataGrid-panelFooter {
      display: block;
      .MuiButton-root {
        all: unset;
        span {
          margin: 0;
        }
      }
      .MuiButton-root {
        font-size: 0;
        .MuiButton-startIcon {
          .MuiSvgIcon-root {
            position: absolute;
            right: 15px;
            margin-top: -10vh;

            background-color: #7f00bb;

            text-align: center;
            width: 30px;
            height: 30px;
            border-radius: 100%;
            color: #fff;
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .joblist {
    padding: 30px 0 0 0;
    .job-button {
      float: right;

      a {
        text-decoration: none;

        button {
          position: relative;
          z-index: 1;
          margin-top: -3px;
          margin-bottom: -60px;
          text-decoration: none;
          font-size: 13px !important;
          padding: 7px 15px !important;
          height: 35px;
        }
      }
    }
    .search {
      border-radius: 10px;
      width: 25vw;
      height: 40px;
      padding: 4px 10px;
    }
    .formControal {
      width: 90px;

      min-width: 80px;

      .select {
        height: 37px;
        color: #7f00bb;
        background: rgba(127, 0, 187, 0.08);
        font-weight: 500;
        font-size: 13px;
        padding: 0;
      }
    }
    .list-btn {
      font-size: 14px;

      button {
        width: auto;
        padding: 8px 15px;
        height: 35px;
      }
    }
    .group {
      button {
        width: auto;
        padding: 8px 15px;
        height: 35px;
      }
    }
    .group {
      .formControal {
        width: 90px;

        min-width: 90px;

        .select {
          height: 37px;
          color: #7f00bb;
          background: rgba(127, 0, 187, 0.08);
          font-weight: 500;
          font-size: 13px;
          padding: 0;
        }
      }
    }
    .apply-btn {
      padding: 12px 15px;
      font-size: 13px;
      height: 35px;
    }

    .MuiButton-root:hover {
      color: #7f00bb;
      background: rgba(127, 0, 187, 0.08);
    }
    .hr {
      color: #000000;
    }
    .body {
      margin-top: 120px !important;

      .MuiBox-root {
        div {
          div {
            .MuiDataGrid-toolbarContainer {
              margin-top: -100px;
              padding-left: 0px;

              button {
                width: auto;
                padding: 7px 15px;
                font-size: 13px;
                margin-right: 4px;
                .MuiButton-startIcon {
                  font-size: 10px !important;
                }
              }
            }
          }
        }
        .MuiDataGrid-main {
          .MuiDataGrid-columnHeaders {
            .MuiDataGrid-columnHeadersInner {
              color: #7f00bb;
            }
          }
        }
      }
      .MuiDataGrid-root {
        div {
          div {
            .MuiDataGrid-toolbarContainer {
              margin-top: -100px;
              border-bottom: 2px solid #7f00bb;
              padding-bottom: 10px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1400px) and (max-width: 2824px) {
  .joblist {
    padding: 30px 0 0 0;

    .job-button {
      margin-left: 635px !important;

      a {
        text-decoration: none;
        margin-bottom: -15px;
        button {
          z-index: 1;
          margin-top: 30px;
          margin-bottom: -80px;
          text-decoration: none;
          font-size: 13px !important;
          padding: 7px 15px !important;
          height: 45px;
        }
      }
    }

    .body {
      margin-top: 130px !important;
    }
  }
}
