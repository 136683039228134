.event-summary {
  height: 100vh;
  overflow-y: scroll;
  .event-banner {
    .dashboard-heading {
      font-size: 36px;
      font-weight: 600;
      color: #7f00bb;
      margin-right: 20px;
      padding-top: 30px;
    }
    .dropdown-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;
      margin-left: 14vw;
      padding-top: 30px;
      .analytic-job {
        display: flex;
        align-items: center;
        .job {
          display: flex;
          align-items: center;
          p {
            color: #999999;
            font-size: 16px;
            font-weight: 500;
            margin-left: 1vw;
          }
        }
      }
    }

    .heading-prograss {
      display: flex;

      .prograss-section {
        display: flex;
        align-items: center;
        .applicant {
          display: flex;
          align-items: center;
          background: $theme-off-white;
          border-radius: 12px;
          padding: 25px 20px;
          width: 230px;
          .text {
            h2 {
              color: #999999;
              font-size: 14px;
              font-weight: 400;
            }
            h1 {
              font-size: 26px;
              font-weight: 600;
            }
          }
        }

        .interview {
          background: $theme-off-white;
          border-radius: 12px;
          padding: 14px 10px;
          width: 220px;
          margin-left: 1vw;
          h2 {
            color: #999999;
            font-size: 13px;
            font-weight: 400;
          }
          h1 {
            font-size: 26px;
            font-weight: 600;
          }

          .text-prograss {
            display: flex;
            align-items: center;
          }
        }

        .post-club {
          background: $theme-off-white;
          border-radius: 12px;
          padding: 25px 10px;
          width: 170px;
          margin-left: 1vw;
          display: flex;
          flex-direction: column;
          h2 {
            color: #999999;
            font-size: 13px;
            font-weight: 400;
          }
          h1 {
            font-size: 26px;
            font-weight: 600;
          }
        }
      }
    }

    .underline {
      margin-top: 4vh;
      height: 2.4px;
      background-color: #999999;
    }
  }
  .dropdown-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50%;
    margin-top: 10px;
    .grap-type {
      display: flex;
      align-items: center;
      p {
        font-size: 12px;
        font-weight: 500;
        color: #999999;
      }
    }
    .View-Data {
      display: flex;
      align-items: center;
      p {
        font-size: 12px;
        font-weight: 500;
        color: #999999;
      }
    }
  }
  .graph {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    .bar-graph {
      width: 100%;
      // height: 1800px ;
      padding-right: 20px;
    }
    .graph-pie-chart {
      height: 600px;

      margin-left: 10px;
      margin-bottom: 30px;

      overflow: scroll;
      .pie-chart {
        margin-top: 20px;
        .pie-chart-graph {
          width: 90%;
          height: 400px;
          display: flex;
          align-items: flex-start;
          .pie1 {
            width: 50%;
            height: 400px;
            display: block;
            margin: auto;
          }
          .pie2 {
            width: 50%;
            height: 400px;
            display: block;
            margin: auto;

            div {
              float: right;
              display: block;
              margin: auto;
            }
          }
          .pie {
            font-size: 24px;
            font-weight: 600;
            margin-top: 20px;
            background: linear-gradient(
              78.58deg,
              #b756a9 -24.19%,
              #cc7a64 142.11%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
          }
        }
      }
    }
    // .line-pie-chart{
    //   width: 90%;
    //   height: 400px;
    //   .line-Chart{
    //     .line-chart-graph{

    //     }
    //   }

    // }
  }
  .default-text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70%;
    font-size: 20px;
    font-weight: 600;
    color: $theme-purple;

    img {
      display: block;
      margin: auto;
    }
  }

  ::-webkit-scrollbar {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .event-summary {
    .event-banner {
      .dashboard-heading {
        font-size: 30px;
        font-weight: 600;
        color: #7f00bb;
        margin-right: 20px;
        padding-top: 30px;
      }
      .dropdown-section {
        display: flex;
        align-items: center;
        flex-direction: column;

        margin-bottom: 30px;
        margin-left: 14vw;
        padding-top: 30px;
        .analytic-job {
          display: flex;
          align-items: center;
          .job {
            display: flex;
            align-items: center;
            p {
              color: #999999;
              font-size: 16px;
              font-weight: 500;
              margin-left: 1vw;
            }
          }
        }
      }

      .heading-prograss {
        display: flex;
        flex-direction: column;
        text-align: left !important;

        .prograss-section {
          display: flex;
          align-items: center;
          .applicant {
            display: flex;
            align-items: center;
            background: $theme-off-white;
            border-radius: 12px;
            padding: 25px 20px;
            width: 230px;
            .text {
              h2 {
                color: #999999;
                font-size: 14px;
                font-weight: 400;
              }
              h1 {
                font-size: 26px;
                font-weight: 600;
              }
            }
          }

          .interview {
            background: $theme-off-white;
            border-radius: 12px;
            padding: 14px 10px;
            width: 220px;
            margin-left: 1vw;
            h2 {
              color: #999999;
              font-size: 13px;
              font-weight: 400;
            }
            h1 {
              font-size: 26px;
              font-weight: 600;
            }

            .text-prograss {
              display: flex;
              align-items: center;
            }
          }

          .post-club {
            background: $theme-off-white;
            border-radius: 12px;
            padding: 25px 10px;
            width: 170px;
            margin-left: 1vw;
            display: flex;
            flex-direction: column;
            h2 {
              color: #999999;
              font-size: 13px;
              font-weight: 400;
            }
            h1 {
              font-size: 26px;
              font-weight: 600;
            }
          }
        }
      }

      .underline {
        margin-top: 4vh;
        height: 2.4px;
        background-color: #999999;
      }
    }
    .dropdown-menu {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 80%;
      margin-top: 10px;
      .grap-type {
        display: flex;
        align-items: center;
        p {
          font-size: 12px;
          font-weight: 500;
          color: #999999;
        }
      }
      .View-Data {
        display: flex;
        align-items: center;
        p {
          font-size: 12px;
          font-weight: 500;
          color: #999999;
        }
      }
    }
    .graph {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-around;

      .graph-pie-chart {
        width: 100%;
        height: 600px;

        margin-left: 10px;
        margin-bottom: 30px;

        overflow: scroll;
        .pie-chart {
          margin-top: 20px;
          .pie-chart-graph {
            width: 100% !important;
            height: 400px;
            flex-direction: column;
            .pie1 {
              width: 100%;
              height: 400px;
            }
            .pie2 {
              width: 100%;
              height: 380px;
              margin-top: 30px;
              div {
                float: left;
              }
            }
            .pie {
              font-size: 24px;
              font-weight: 600;
              margin-top: 20px;
              background: linear-gradient(
                78.58deg,
                #b756a9 -24.19%,
                #cc7a64 142.11%
              );
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
            }
          }
        }
      }
      // .line-pie-chart{
      //   width: 90%;
      //   height: 400px;
      //   .line-Chart{
      //     .line-chart-graph{

      //     }
      //   }

      // }
    }
    .card-wrapper {
      background-color: $theme-off-white;
      border-radius: 12px;
      width: 400px;
      padding: 20px;

      scrollbar-width: 0px;
      margin-bottom: 30px;
      p {
        position: sticky;
        font-size: 16px;
        color: #999999;
        margin-bottom: 20px;
      }
      .card-rapper {
        height: 400px;
        overflow: scroll;
        .card {
          background: #fff;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          padding: 10px 20px;
          border-radius: 20px;
          margin-bottom: 20px;
          h2 {
            font-size: 18px;
            font-weight: 500;
          }
          h1 {
            font-size: 18px;
            font-weight: 600;
            background: linear-gradient(
              78.58deg,
              #b756a9 -24.19%,
              #cc7a64 142.11%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
          }
        }
      }
    }
    ::-webkit-scrollbar {
      display: none;
    }
  }
}
