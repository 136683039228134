.num-icon {
  padding: 1px;
  width: 20px;
  font-size: 14px;
  font-weight: 600;
  background: blue;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  color: $theme-white;

  &:hover {
    background: #7F00BB !important;
  }
}

.num-icon .active {
  background: red;
}

.modal {
  background-color: $theme-off-white;
  width: 90%;
  max-width: 75vw;
  max-height: 90vh;
  min-height: 20vh;
  border-radius: 10px;
  padding: 30px 40px;
  overflow-y: scroll;
  overflow-x: hidden;

  h2 {
    font-size: 25px;
      font-weight: 600;
      color: $theme-purple;
  }

  .chips {
    margin: 20px 0;

    .chip {
      border-radius: 6px;
      margin: 5px;
      text-transform: capitalize;
      color: $theme-black;
      font-weight: 500;
    }

    .outlined {
      background: $theme-white;
      border: 1px solid rgba($theme-black, 0.2);
    }

    .filled {
      background: $theme-black-two;
      color: $theme-white;
    }
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 50px;

    .left {
      width: 100%;
      flex: 0.9;
    }
    .right {
      height: 300px;
      width: 320px;
      background: $theme-dark-blue;
      border-radius: 15px;
      display: flex;
      align-items: center;
      padding: 20px;

      div {
        p {
          color: $theme-white;
          font-size: 12px;
        }
        h2 {
          color: $theme-purple;
          font-size: 28px;
        }
      }

      .illus {
        height: 280px;
        margin-right: -50px;
        position: relative;
        right: -50px;
      }
    }
  }

  .btns {
    text-align: right;
    button {
      margin: 5px 15px;
      font-weight: 500;
    }
  }
}



/* icons */

@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?dagg2b');
  src:  url('../fonts/icomoon.eot?dagg2b#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?dagg2b') format('truetype'),
    url('../fonts/icomoon.woff?dagg2b') format('woff'),
    url('../fonts/icomoon.svg?dagg2b#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-num_9 .path1:before {
  content: "\e902";
}
.icon-num_9 .path2:before {
  content: "\e903";
  margin-left: -1em;
  color: $theme-white;
}
.icon-num_8 .path1:before {
  content: "\e904";
}
.icon-num_8 .path2:before {
  content: "\e905";
  margin-left: -1em;
  color: $theme-white;
}
.icon-num_8 .path3:before {
  content: "\e906";
  margin-left: -1em;
  color: $theme-white;
}
.icon-num_7 .path1:before {
  content: "\e907";
}
.icon-num_7 .path2:before {
  content: "\e908";
  margin-left: -1em;
  color: $theme-white;
}
.icon-num_6 .path1:before {
  content: "\e909";
}
.icon-num_6 .path2:before {
  content: "\e90a";
  margin-left: -1em;
  color: $theme-white;
}
.icon-num_5 .path1:before {
  content: "\e90b";
}
.icon-num_5 .path2:before {
  content: "\e90c";
  margin-left: -1em;
  color: $theme-white;
}
.icon-num_5 .path3:before {
  content: "\e90d";
  margin-left: -1em;
  color: $theme-white;
}
.icon-num_4 .path1:before {
  content: "\e90e";
}
.icon-num_4 .path2:before {
  content: "\e90f";
  margin-left: -1em;
  color: $theme-white;
}
.icon-num_3 .path1:before {
  content: "\e910";
}
.icon-num_3 .path2:before {
  content: "\e911";
  margin-left: -1em;
  color: $theme-white;
}
.icon-num_2 .path1:before {
  content: "\e912";
}
.icon-num_2 .path2:before {
  content: "\e913";
  margin-left: -1em;
  color: $theme-white;
}
.icon-num_1 .path1:before {
  content: "\e914";
}
.icon-num_1 .path2:before {
  content: "\e915";
  margin-left: -1em;
  color: $theme-white;
}
.icon-num_10 .path1:before {
  content: "\e900";
}
.icon-num_10 .path2:before {
  content: "\e901";
  margin-left: -1em;
  color: $theme-white;
}
