.email-sender {
  padding: 30px 50px;
  height: 100vh;
  overflow-y: scroll;

  .head-text {
    background: $theme-purple;
    padding: 20px 45px;
    border-radius: 16px;
    h1 {
      color: $theme-off-white;
    }
    h2 {
      font-weight: 500;
      color: $theme-off-white;
    }
  }

  .all-fields {
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    h2 {
      color: $theme-purple;
      font-weight: 600;

      span {
        font-size: 12px;
      }
    }

    .templates,
    .type,
    .emails,
    .filters,
    .subject,
    .body {
      .row {
        display: flex;
        column-gap: 20px;
        align-items: center;

        & > * {
          flex: 1 1 20%;
        }

        h2 {
          flex: 0.3;
          font-weight: 600;
        }
      }
    }
  }

  .submit {
    text-align: center;
  }
}
