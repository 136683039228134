.portal {
    display: flex;
    // column-gap: 150px;
    overflow: hidden;
    height: fit-content;
  
  
    .content-container {
      width: 100%;
      // overflow-y: scroll;
      height: fit-content;
    }
  }

  