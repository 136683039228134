.summary {
  padding-right: 20px;
  font-family: "Poppins", sans-serif;
  .summary-banner {
    .dropdown-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;
      margin-left: 14vw;
      padding-top: 30px;
      .analytic-job {
        display: flex;
        align-items: center;
        .job {
          display: flex;
          align-items: center;
          p {
            color: #999999;
            font-size: 16px;
            font-weight: 500;
            margin-left: 1vw;
          }
        }
      }
    }

    .heading-prograss {
      display: flex;
      align-items: center;

      .dashboard-heading {
        font-size: 36px;
        font-weight: 600;
        color: #7f00bb;
        margin-right: 20px;
      }

      .prograss-section {
        display: flex;
        align-items: center;
        .applicant {
          display: flex;
          align-items: center;
          background: #f8f9fa;
          border-radius: 12px;
          padding: 25px 20px;
          width: 230px;
          .text {
            h2 {
              color: #999999;
              font-size: 14px;
              font-weight: 400;
            }
            h1 {
              font-size: 26px;
              font-weight: 600;
            }
          }
        }

        .interview {
          background: #f8f9fa;
          border-radius: 12px;
          padding: 25px 10px;
          width: 220px;
          margin-left: 1vw;
          h2 {
            color: #999999;
            font-size: 13px;
            font-weight: 400;
          }
          h1 {
            font-size: 26px;
            font-weight: 600;
          }

          .text-prograss {
            display: flex;
            align-items: center;
          }
        }

        .post-club {
          background: #f8f9fa;
          border-radius: 12px;
          padding: 25px 10px;
          width: 170px;
          margin-left: 1vw;
          display: flex;
          flex-direction: column;
          h2 {
            color: #999999;
            font-size: 13px;
            font-weight: 400;
          }
          h1 {
            font-size: 26px;
            font-weight: 600;
          }
        }
      }
    }

    .underline {
      margin-top: 4vh;
      height: 2.4px;
      background-color: #999999;
    }
  }

  .chart {
    display: flex;
    align-items: center;
    margin: 5vh 0;
    .chart-bar-graph {
      display: flex;
      flex-direction: column;
      .chart-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 2vw;
        .chart-type {
          display: flex;
          align-items: center;
        }
        .view{
            display: flex;
            align-items: center; 
        }
      }
    }
  }
  .workShop {
    display: flex;
    align-items: center;
    .workshop-left {
      width: 60vw;
      background-color: #f8f9fa;
      padding: 20px 10px;
      .head {
        display: flex;
        align-items: center;
        
        margin-bottom: 10px;
        padding: 8px 13px;
        p {
          font-size: 14px;
          font-weight: 500;
          width: 200px;
    

          color: #00000099;
          opacity: 0.6;
        }
      }
      .card {
        display: flex;
        align-items: center;
        
        background-color: #fff;
        padding: 8px 13px;
        border-radius: 12px;
        margin-bottom: 20px;
        p {
          font-size: 15px;
          font-weight: 500;
          width: 200px;
        }
        .event{
            width: 180px;
            font-size: 12px;
            width: 200px;
        }
        .button {
          background-color: #f8f9fa;
          border-radius: 20px;
          padding: 5px 10px;
          color: #7f00bb;
          font-size: 16px;
          font-weight: 500;
          width:100px
        }
      }
    }
    .right {
      background-color: #f8f9fa;
      width: 300px;
      height: auto;
      margin-left: 30px;
      padding: 5px 20px;
      border-radius: 12px;
      margin-top: -18vh;
      .head {
        p {
          font-size: 16px;
          color: #00000066;
          opacity: 0.6;
          margin-bottom: 10px;
        }
      }
      .card {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #fff;
        padding: 8px 16px;
        border-radius: 12px;
        margin-bottom: 20px;
        p {
          font-size: 18px;
          font-weight: 500;
          color: black;
        }
        .button {
          border-radius: 20px;
          padding: 5px 10px;
          background: linear-gradient(
            78.58deg,
            #b756a9 -24.19%,
            #cc7a64 142.11%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;

          font-size: 16px;
          font-weight: 500;
        }
      }
    }
  }
}
