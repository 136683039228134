.user-details {
  padding: 30px 50px;
  height: 100vh;
  overflow-y: scroll;

  .head-text {
    background: $theme-purple;
    padding: 20px 45px;
    border-radius: 16px;
    h1 {
      color: $theme-off-white;
    }
    h2 {
      font-weight: 500;
      color: $theme-off-white;
    }
  }

  .all-details {
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    h2 {
      color: $theme-purple;
      font-weight: 600;

      span {
        font-size: 12px;
      }
    }

    .overview,
    .basic,
    .education,
    .work,
    .preference,
    .social {
      &_fields {
        display: flex;
        flex-direction: column;
        row-gap: 15px;
        padding: 10px 0;

        .images {
          display: flex;

          .profile {
            position: relative;
            .user-img {
              border-radius: 50%;
              margin-right: 20px;
            }
            .camera {
              position: absolute;
              height: 30px;
              width: 30px;
              right: 40px;
              bottom: 65px;
              z-index: 1;
              cursor: pointer;
              padding: 5px;
              border-radius: 50%;
              background: rgba(0, 0, 0, 0.62);
              color: $theme-white;
            }
          }

          .banner {
            position: relative;
            .banner-img {
              border-radius: 12px;
              max-width: 100%;
              height: auto;
            }
            .camera {
              position: absolute;
              right: 15px;
              top: 15px;
              cursor: pointer;
            }
          }
        }

        .row {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          column-gap: 20px;

          & > * {
            flex: 1 1 25%;
          }
        }
      }
    }

    .metric {
      &_fields {
        display: flex;
        flex-direction: column;
        row-gap: 15px;
        padding: 10px 0;

        .row {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          column-gap: 20px;

          & > * {
            flex: 1 1 20%;
          }
        }
      }
    }

    .settings {
      &_fields {
        padding: 10px 0;

        .row {
          display: flex;
          column-gap: 20px;
          align-items: center;

          h6 {
            flex: 0.3;
            font-weight: 600;
          }
        }
      }
    }
  }

  .submit {
    text-align: center;
  }
}
