.change-password {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 50px;

  .input-fields {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }
}