.job-dashboard {
  padding-top: 5vh;
  height: 100vh;
  overflow-y: scroll;
  .name-card {
    background: linear-gradient(88.89deg, #7f00bb 0%, #a83bdb 143.31%);
    width: 65vw;
    height: 18vh;
    padding: 20px 0;
    padding-left: 5vw;
    border-radius: 10px;
    margin-left: 5vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .name {
      h1 {
        font-size: 24px;
        font-weight: 600;
        color: #ffff;
      }
      p {
        color: #ffff;
        font-size: 18px;
        font-weight: 500;
      }
    }

    .img {
      width: 200px;
      height: 200px;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
  .job-select {
    margin-left: 5vw;
  }
  .cards {
    margin-top: 30px;
    display: flex;
    align-items: center;
    margin-left: 5vw;

    .card {
      display: flex;
      align-items: center;
      background: $theme-off-white;
      min-width: 210px;
      padding: 10px;
      margin-right: 30px;
      border-radius: 10px;
      .text {
        margin-right: 20px;
        p {
          font-size: 14px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.4);
        }
        h1 {
          font-size: 24px;
          font-weight: 600;
          margin-top: 5px;
        }
        h3 {
          font-size: 14px;
          font-weight: 400;
        }
      }
      .progress {
        .MuiBox-root {
          .MuiCircularProgress-root {
            width: 60px !important;
            height: 60px !important;

            color: #7f00bb;
            .MuiCircularProgress-svg {
              .MuiCircularProgress-circle {
                stroke-width: 4px;
              }
            }
          }
        }
      }
    }

    .custom {
      button {
        outline: none;
        border: none;
        padding: 13px 30px;
        background: #333333;
        color: #ffff;
        margin-right: 10vw;
        font-size: 14px;
        font-weight: 500;
        border-radius: 5px;
        cursor: pointer;
      }
    }
  }
  .graph-h1 {
    margin-top: 40px;
    h1 {
      margin-left: 5vw;
      margin-top: 10px;
    }
    .graph {
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      .bar-graph {
        background: $theme-off-white;
        width: 50vw;
        height: 400px;
        margin-left: 4vw;
        padding-right: 0px;
      }

      .check-pie {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        height: 400px;
        background: $theme-off-white;
        width: 350px;
        margin-left: 30px;
        h1 {
          font-size: 20px;
          font-weight: 600;
          color: #7f00bb;
        }
        .check {
          display: flex;
          align-items: center;

          padding: 20px;
          padding-left: 30px;
          .checkbox1 {
            color: #000000;
          }
        }
        .pie {
          width: 100%;
          height: 200px;
        }
      }
    }
  }
}
