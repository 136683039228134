.candidate-details {
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 50px;
  font-family: "Poppins", sans-serif;
  display: flex;
  column-gap: 20px;

  &-card {
    width: 75%;
    height: max-content;

    h1 {
      font-size: 30px;
      font-weight: 600;
      color: $theme-purple;
    }

    .banner {
      position: relative;
      margin-top: 10px;
      width: fit-content;

      .cover-img {
        width: 100%;
      }

      button {
        position: absolute;
        bottom: 20px;
        right: 50px;
        background: $theme-white;
        color: $theme-grey;
        text-transform: capitalize;
        padding: 7px 24px;
        border-radius: 18px;
      }
    }

    .lower {
      background: $theme-off-white;
      border: 1px solid rgba($theme-black, 0.2);
      border-radius: 0 0 15px 15px;
      margin-top: -10px;
      padding-top: 15px;
      .resume {
        position: relative;
        z-index: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: 5%;
        margin-right: 5%;
        div {
          .profile-img {
            border-radius: 50%;
            border: 6px solid $theme-purple;
            margin-top: -70px;
            max-height: 150px;
            width: auto;
          }
          .avatar {
            height: 100px;
            width: 100px;
            font-size: 65px;
            margin-top: -70px;
            background: $theme-dark-blue;
          }
          h4 {
            color: $theme-purple;
            text-align: center;
          }
        }
        .view {
          text-align: center;
          button {
            display: block;
            text-transform: capitalize;
            font-size: 13px;
            font-weight: 500;
          }
          .view-btn {
            background: $theme-dark-blue;
            color: $theme-white;
            font-weight: 600;
            padding: 5px 20px;
            border-radius: 12px;
          }
          .download-btn {
            text-decoration: underline;
            color: $theme-black;
          }
        }
      }

      // .scrollable {
      //   height: 56vh;
      //   overflow-y: scroll;
      //   &::-webkit-scrollbar {
      //     display: none;
      //   }
      // }

      .basic-details {
        width: 90%;
        margin: auto;
        margin-top: 30px;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;

        h4 {
          font-size: 16px;
          font-weight: 600;
          color: $theme-purple;
        }

        .education {
          .edu h5 {
            display: flex;
            align-items: center;
            column-gap: 5px;
            font-weight: 500;
          }
        }
        .hr {
          width: 1px;
          height: inherit;
          background: gray;
          margin: 0;
        }

        .experience {
          width: 40%;
          .work {
            div {
              display: flex;
              justify-content: space-between;
            }
            h5 {
              font-weight: 500;
              display: inline-block;
            }
            p {
              display: inline-flex;
              align-items: center;
              column-gap: 5px;
              text-align: right;
              color: rgba($theme-black, 0.6);
              font-size: 14px;
            }
            h6 {
              display: inline-flex;
              align-items: center;
              column-gap: 5px;
              font-size: 14px;
              font-weight: 500;
              color: rgba($theme-black, 0.6);
            }
          }
        }

        .metrics {
          width: 25%;
          div {
            display: flex;
            justify-content: space-between;

            h5 {
              font-weight: 500;
              span {
                @include text-gradient(right);
                font-size: 18px;
                font-weight: 600;
                margin-left: 6px;
              }
            }
          }
        }
      }

      .questions {
        width: 90%;
        margin: auto;
        margin-top: 10px;
        position: relative;
        background: $theme-white;

        h4 {
          color: $theme-text-grey;
          font-size: 14px;
          font-weight: 500;
        }
        .que {
          margin-bottom: 20px;
        }

      }
    }
  }

  .score-card {
    width: max-content;
    margin-top: 10px;
    position: fixed;
    left: 78%;

    .index {
      text-align: right;
      display: flex;
      align-items: center;
      h2 {
        color: $theme-purple;
        font-weight: 600;
      }
    }
    .status {
      width: 100%;
      min-width: 200px;
      max-width: 300px;
      background: $theme-off-white;
      padding: 25px;
      border-radius: 15px;
      border: 1px solid rgba($theme-black, 0.2);

      h2 {
        font-size: 22px;
        font-weight: 600;
        color: $theme-purple;
      }
    }

    .scores {
      width: 100%;
      background: $theme-off-white;
      padding: 25px;
      border-radius: 15px 15px 0 0;
      border: 1px solid rgba($theme-black, 0.2);
      margin-top: 30px;
      max-height: 50vh;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }

      .top-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h2 {
          font-size: 22px;
          font-weight: 600;
          color: $theme-purple;
        }
      }
    }
    .enable {
      background: $theme-off-white;
      padding: 25px;
      border-radius: 15px;
      border: 1px solid rgba($theme-black, 0.2);
      backdrop-filter: blur(3px);
      button {
        margin: auto;
        display: block;
        background: $theme-purple; 
        color: $theme-white;
        text-transform: capitalize;
        padding: 10px 20px;
      }
    }
    .total-card {
      background: $theme-dark-blue;
      color: $theme-white;
      border-radius: 0 0 15px 15px;
      padding: 20px;
    }
  }

  .event-buttons {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-grow: 1;
    margin-top: 60px;

    button {
      background: $theme-purple;
      color: $theme-white;
      text-transform: capitalize;
      border-radius: 8px;
      padding: 8px 16px;
    }
  }
}


@media screen and (max-width: 1064px) {
  .candidate-details {
    overflow-x: scroll;
  }
}