.edit-job {
  padding: 30px 50px;
  height: 100vh;
  overflow-y: scroll;

  .head-text {
    background: $theme-purple;
    padding: 20px 45px;
    border-radius: 16px;
    h1 {
      color: $theme-off-white;
    }
    h2 {
      font-weight: 500;
      color: $theme-off-white;
    }
  }

  .all-details {
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    h2 {
      color: $theme-purple;
      font-weight: 600;
    }

    .basic {
      &_fields {
        display: flex;
        flex-direction: column;
        row-gap: 15px;
        padding: 10px 0;

        .row {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          column-gap: 20px;

          & > * {
            flex: 1 1 25%;
          }
        }
      }
    }

    .overviews {
      &_fields {
        padding: 10px 0;

        .firm, .experience {
          h3 {
            font-weight: 600;
            margin: 20px 0 10px 0;
          }
        }
      }
    }

    .role-n-industries {
      &_fields {
        padding: 10px 0;
        .roles {
          margin-bottom: 20px;
          margin-top: 10px;
        }
      }
    }

    .settings,
    .media {
      &_fields {
        padding: 10px 0;

        .row {
          display: flex;
          column-gap: 20px;
          align-items: center;

          h6 {
            flex: 0.3;
            font-weight: 600;
          }
        }
      }
    }

    .questions {
      &_fields {
        padding: 10px 0;

        .add-btn {
          text-transform: capitalize;
          font-weight: 500;
          margin-top: 30px;
        }

        .ques {
          margin: 10px 0;
          .que {
            margin: 10px 0;
            h3 {
              font-weight: 600;
            }
            h4 {
              font-weight: 500;
            }
          }
        }
      }
    }
  }

  .submit {
    text-align: center;
  }
}
