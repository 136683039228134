.add-event {
  padding: 30px 50px;
  height: 100vh;
  overflow-y: scroll;

  .head-text {
    background: $theme-purple;
    padding: 20px 45px;
    border-radius: 16px;
    h1 {
      color: $theme-off-white;
    }
    h2 {
      font-weight: 500;
      color: $theme-off-white;
    }
  }

  .all-details {
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    h2 {
      color: $theme-purple;
      font-weight: 600;
    }

    .basic {
      &_fields {
        display: flex;
        flex-direction: column;
        row-gap: 15px;

        .dates {
          display: flex;
          justify-content: space-between;
          gap: 10px;
        }
      }
    }

    .settings,
    .media {
      &_fields {
        padding: 10px 0;

        .row {
          display: flex;
          column-gap: 20px;
          align-items: center;

          h6 {
            flex: 0.3;
            font-weight: 600;
          }
        }
      }
    }

    .speakers-n-orgs {
      &_fields {
        padding: 10px 0;

        .speaker_fields {
          .row {
            display: flex;
            column-gap: 20px;
            align-items: center;
            margin: 10px 0;
          }
        }
      }
    }

    .questions {
      &_fields {
        padding: 10px 0;

        .add-btn {
          text-transform: capitalize;
          font-weight: 500;
          margin-top: 30px;
        }

        .ques {
          margin: 10px 0;

          .que {
            margin: 10px 0;
            h3 {
              font-weight: 600;
            }
            h4 {
              font-weight: 500;
            }
          }
        }
      }
    }
  }

  .submit {
    text-align: center;
  }
}
